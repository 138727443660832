.Home-page-main-wrapper {
  padding: 40px;
}
.nav-bar-with-logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}
.logo img {
  height: auto;
  max-width: 300px;
  width: 100%;
}
.links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.links p {
  cursor: pointer;
  margin-top: 28px;
}
.main-content {
  text-align: center;
  background-color: #ed1c24;
  padding: 150px;
  margin-top: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.main-content span {
  font-size: 20px;
  font-weight: 700;
}
.main-content p {
  color: white;
}
.select {
  padding: 5px 3px;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid black;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: none;
}

.option {
  padding: 8px 16px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}
.link {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.link::before,
.link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 100%;
  bottom: -2px;
  height: 2px;
  background-color: rgb(232, 0, 0);
  transition: right 0.5s ease;
}

.link::after {
  right: 0;
  left: 100%;
  transition: left 0.5s ease;
}

.link:hover::before,
.link:hover::after {
  right: 0;
}
/* dropddown */

@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Bree+Serif);

a {
  color: #fff;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  cursor: pointer;
}

nav ul li {
  display: inline-block;
  background-color: #ffffff;
}

nav a {
  display: block;
  padding: 0 10px;
  color: black;
  /* font-size: 20px; */
  line-height: 60px;
  text-decoration: none;
  width: 150px;
}

nav a:hover {
  background-color: #ed1c24;
}

nav ul ul {
  display: none;
  position: absolute;
}

nav ul li:hover > ul {
  display: inherit;
}

nav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}

nav ul ul ul li {
  position: relative;
}

li > a:only-child:after {
  content: "";
}
.mainli {
  background-color: white;
  margin-top: 15px;
}
.mobile-nav{
    display: none;
}
/*  */
@media only screen and (max-width: 950px) {
  .main-content {
    padding: 80px;
    color: white;
  }
  nav a {
    width: auto;
  }
}
@media only screen and (max-width: 750px) {
.desktop-nav{
display: none;
}
.mobile-nav{
    display: flex;
}
}

@media only screen and (max-width: 650px) {
  .Home-page-main-wrapper {
    padding: 20px;
  }
  .nav-bar-with-logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
  }
  .logo img {
    height: auto;
    max-width: 200px;
    width: 100%;
  }
  .main-content {
    padding: 20px;
    color: white;
  }
  .main-content span {
    font-size: 15px;
}
}

@media only screen and (max-width: 350px) {
  .links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: 10px;
  }
  .logo img {
    max-width: 150px;
  }
  .Home-page-main-wrapper {
    padding: 2px;
  }

}
.mobile{
    padding: 4px;
    background-color: rgb(232, 0, 0);
    
   
}
